.ContentVideo {
    .vjs-fluid {
        width: 100%;
        height: 100%;
        padding-top: 0 !important;

        video {
            width: 100%;
            height: 100%;
        }
    }

    .vjs-loading-spinner {
        ::after {
            content: unset;
        }
    }

    .vjs-control-text {
        display: none;
    }
}
