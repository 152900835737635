@keyframes kenburns-keyframes {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.1);
    }
}

.single-image {
    width: 100%;
    height: auto;
}

.animation {
    &--Kenburn {
        animation-name: kenburns-keyframes;
        animation-timing-function: ease-out;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-duration: 5s;
    }
}
