@keyframes blink-animation {
    50% {
        opacity: 0;
    }
}

.icon-list {
    display: flex;
    flex-direction: row;

    &__wrapper {
        margin: 2px 2px;
        padding: 10px;
        border-radius: 10px;
        color: red;
        background-color: white;
    }

    &--blink {
        animation: blink-animation 5s ease-in-out infinite;
    }
}
