.ticker__element {
    display: inline-block;
    font-size: calc(var(--ticker-height) - var(--ticker-height) / 1.45);
    line-height: var(--ticker-height);

    &--bold {
        font-weight: bold;
    }

    &__spacer {
        letter-spacing: 10px;
        padding-left: calc(1rem + 10px);
        padding-right: 1rem;
    }
}
