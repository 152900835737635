.change-arrows {
    position: absolute;
    z-index: 1000000;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    font-size: 3rem;
    pointer-events: none;

    &--locked {
        --fa-primary-opacity: 0.3 !important;
        --fa-secondary-opacity: 0.3 !important;

        i:hover {
            transform: unset !important;
        }
    }

    i {
        transition: 100ms ease-in-out;
        pointer-events: all;
    }

    i:hover {
        transform: scale(1.3);
    }
}
