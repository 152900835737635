@use "sass:math";

$fw: 400;
$fw-light: 300;
$fw-bold: 600;
$fw-bolder: 700;
$fc-light: #666666;
$fc: #111111;
$ff: 'Open Sans', sans-serif;
$border-color: #e9e3db;
$fs-root-int: 16;
$fs-root: $fs-root-int + px;
$fs-small: math.div(12, $fs-root-int) + rem;
$duration: 0.2s;
$hc: #005a93;
$hc-hover: #005184;
$dist: 30px;
$line-height-int: 1.5;
$line-height-small-int: 1.25;
$line-height: $line-height-int + em;
$line-height-small: $line-height-small-int + em;
$radius: 3px;
$ac: #fdcb00;
$box-color: #cccccc;
$box-color-light: #eaeaea;
$fs-h1: math.div(40, $fs-root-int) + rem;
$fs-h2: math.div(36, $fs-root-int) + rem;
$fs-h3: math.div(32, $fs-root-int) + rem;
$fs-h4: math.div(28, $fs-root-int) + rem;
$fs-h5: math.div(24, $fs-root-int) + rem;
$fs-h6: math.div(20, $fs-root-int) + rem;
