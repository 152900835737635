@import '../../../assets/styles/variables';

.weather {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 15px;

    &__city {
        color: $fc-light;
        padding: 15px;
        margin-top: 30px;
        border-radius: 5px;
    }

    &__cards {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        flex-wrap: wrap;
        width: 100%;
        height: 100%;
        margin-top: -30px;
        margin-left: -30px;
        justify-content: center;
    }
}
