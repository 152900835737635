.display-icons {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 99999999999999;
    transition: all 250ms ease-in-out;
}

.slide-container {
    display: flex;
    flex-direction: column;
}

.slides {
    flex-grow: 1;
}

.content-container {
    display: flex;
    flex-direction: column;

    & > .slide-container {
        flex-grow: 8;
    }

    & > .slide-preview {
        flex-grow: 1;
    }
}
