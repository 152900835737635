/* open-sans-300 - latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Open Sans Light'), ocal('OpenSans-Light'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-300.woff2') format('woff2'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-300italic - latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    src: local('Open Sans Light Italic'), ocal('OpenSans-LightItalic'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-300italic.woff2') format('woff2'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-regular - latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), ocal('OpenSans-Regular'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-regular.woff2') format('woff2'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-italic - latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: local('Open Sans Italic'), ocal('OpenSans-Italic'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-italic.woff2') format('woff2'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-600 - latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), ocal('OpenSans-SemiBold'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-600.woff2') format('woff2'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-600italic - latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: local('Open Sans SemiBold Italic'), ocal('OpenSans-SemiBoldItalic'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-600italic.woff2') format('woff2'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-700 - latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Open Sans Bold'), ocal('OpenSans-Bold'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-700.woff2') format('woff2'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-700italic - latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src: local('Open Sans Bold Italic'), ocal('OpenSans-BoldItalic'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-700italic.woff2') format('woff2'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-800 - latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: local('Open Sans ExtraBold'), ocal('OpenSans-ExtraBold'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-800.woff2') format('woff2'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-800italic - latin-ext */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    src: local('Open Sans ExtraBold Italic'), ocal('OpenSans-ExtraBoldItalic'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-800italic.woff2') format('woff2'),
        url('../fonts/open-sans/open-sans-v17-latin-ext-800italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
