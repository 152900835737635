.analog-clock {
    position: relative;
    margin: 0 auto;

    &__center-dot {
        display: block;
        position: absolute;
        width: 4%;
        padding-bottom: 4%;
        height: 0;
        z-index: 100;
        border-radius: 50%;
    }

    .react-clock {
        border: 10px solid #000000;
        border-radius: 50%;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.125) inset;

        &__face {
            background: transparent;
            margin: 3%;
        }

        &__second-hand__body {
            box-shadow: -4px -6px 0 0 rgba(0, 0, 0, 0.15);
        }
    }
}
