@import-normalize;

@import 'fonts';
@import 'variables';
@import 'reset';
@import 'fontawesome';

html {
    font-size: $fs-root;
    font-weight: $fw;
    font-family: $ff;
    color: $fc;
}

html,
body,
.full-size {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
}

.center {
    height: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hidden {
    visibility: hidden;
}

#root {
    width: 100vw;
    height: 100vh;
}

.slides {
    position: relative;
    width: 100%;
    height: 100%;
}

* {
    font-family: $ff;
    user-select: none;
}

*,
*::before,
*::after {
    box-sizing: border-box;
}
