.signpost {
    flex-direction: column;
    justify-content: space-evenly;
    padding: 30px !important;

    &__needle {
        flex-grow: 0;
        width: auto;
        height: auto;
        margin: 20pt 40pt;
    }

    &__description {
        flex-grow: 1;
        font-weight: bold;
        margin: 0 40pt;
    }
}
