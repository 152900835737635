@import '../../../assets/styles/variables.scss';

.news-ticker {
    width: 100%;
    z-index: 1000;
    display: block;
    white-space: nowrap;

    &--no-float {
        position: relative;
    }

    &--float {
        position: absolute;
    }

    &--top {
        border-bottom: 2px solid #2f2f2f;
    }

    &--bottom {
        order: 10;
        border-top: 2px solid #2f2f2f;
    }
}
