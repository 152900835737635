@mixin small-scrollbar {
    &::-webkit-scrollbar {
        width: 2px;
        height: 5px;
    }

    &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
    }

    &::-webkit-scrollbar-thumb {
        background: #d1d1d1;
        border: 0 solid #ff7a7a;
        border-radius: 50px;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #ffffff;
    }

    &::-webkit-scrollbar-thumb:active {
        background: #ffffff;
    }

    &::-webkit-scrollbar-track {
        background: #666666;
        border: 0 none #aaaaaa;
        border-radius: 50px;
    }

    &::-webkit-scrollbar-track:hover {
        background: #666666;
    }

    &::-webkit-scrollbar-track:active {
        background: #333333;
    }

    &::-webkit-scrollbar-corner {
        background: transparent;
    }
}

.slide-preview {
    white-space: nowrap;
    background-color: white;
    z-index: 10000;
    display: flex;
    justify-content: center;
    height: 80px;

    &--overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: transparent;
    }

    &--scroll-container {
        @include small-scrollbar();

        max-height: 100%;
        overflow: auto;
        overflow-y: hidden;
        display: flex;
        justify-content: center;
    }

    &--flex-container {
        display: flex;
        flex-direction: row;
        width: 100%;

        &::before,
        &::after {
            content: '';
            border-left: 10px solid transparent;
        }
    }

    &__item {
        display: inline-block;
        margin: auto;
        margin-left: 5px;
        border-radius: 5px;
        min-width: 180px;
        width: 250px;
        height: 85%;
        color: white;
        line-height: 1.2em;
        font-weight: 500;
        padding: 5px;
        border-width: 2px;
        border-style: solid;
        background-size: cover !important;
        background-repeat: no-repeat !important;
        background-position-x: center !important;
        background-position-y: center !important;

        &:first-child {
            margin-left: 0;
        }

        &--locked {
            color: lightgray;
        }
    }

    &__text-wrapper {
        display: table !important;
        flex-direction: column;
        justify-content: stretch;
        align-items: center;

        span {
            display: table-cell;
            vertical-align: middle;
            text-align: center;
            word-break: break-word;
            white-space: normal;
        }
    }
}
