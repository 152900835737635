@import '../../../assets/styles/variables';
@import '../../../assets/styles/extends';

.weather-card {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
    padding: 15px;
    margin-left: 30px;
    margin-top: 30px;
    min-width: 270px;
    max-width: 350px;
    border-radius: 10px;

    i {
        --fa-primary-color: #{$fc};
        --fa-secondary-color: #{$ac};
        --fa-primary-opacity: 1;
        --fa-secondary-opacity: 1;
    }

    &__header {
        margin-bottom: 15px;
    }

    &__body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__date {
        font-weight: $fw-bold;
        white-space: nowrap;

        span {
            display: inline-block;
        }
    }

    hr {
        background: black;
    }

    &__temperature {
        &--current {
            white-space: nowrap;
            font-size: $fs-h2;
            font-weight: $fw-bold;
            margin-bottom: 1rem;
        }

        &--range {
            white-space: nowrap;
        }
    }

    &__description {
        margin-left: 30px;
        text-align: center;

        i {
            font-size: 3rem;
        }

        small {
            font-size: $fs-small;
            display: block;
            margin-top: 1rem;
            line-height: 1.25em;
        }
    }

    &-break {
        flex-basis: 100%;
        height: 0;
    }
}
