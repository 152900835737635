$duration: 850ms;
$easing: ease-out;

/**
 * Fade
 */

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: all $duration $easing;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: all $duration $easing;
}

/**
 * Scale up
 */

.scaleup-enter {
    transform: scale(0.85);
}

.scaleup-enter-active {
    transform: scale(1);
    transition: all $duration $easing;
}

.scaleup-exit {
    transform: scale(1);
}

.scaleup-exit-active {
    transform: scale(0.85);
    transition: all $duration $easing;
}

/**
 * Scale down
 */

.scaledown-enter {
    transform: scale(1.25);
}

.scaledown-enter-active {
    transform: scale(1);
    transition: all $duration $easing;
}

.scaledown-exit {
    transform: scale(1);
}

.scaledown-exit-active {
    transform: scale(1.25);
    transition: all $duration $easing;
}

/**
 * Slide in left
 */

.slideinleft-enter {
    transform: translateX(100%);
}

.slideinleft-enter-active {
    transform: translateX(0);
    transition: all $duration $easing;
}

.slideinleft-exit {
    transform: translateX(0);
}

.slideinleft-exit-enter {
    transform: translateX(-100%);
    transition: all $duration $easing;
}

/**
 * Slide in right
 */

.slideinright-enter {
    transform: translateX(-100%);
}

.slideinright-enter-active {
    transform: translateX(0);
    transition: all $duration $easing;
}

.slideinright-exit {
    transform: translateX(0);
}

.slideinright-exit-enter {
    transform: translateX(100%);
    transition: all $duration $easing;
}

/**
 * Slide in down
 */

.slideindown-enter {
    transform: translateY(-100%);
}

.slideindown-enter-active {
    transform: translateY(0%);
    transition: all $duration $easing;
}

.slideindown-exit {
    transform: translateY(0%);
}

.slideindown-exit-active {
    transform: translateY(100%);
    transition: all $duration $easing;
}

/**
 * Slide in up
 */

.slideinup-enter {
    transform: translateY(100%);
}

.slideinup-enter-active {
    transform: translateY(0%);
    transition: all $duration $easing;
}

.slideinup-exit {
    transform: translateY(0%);
}

.slideinup-exit-active {
    transform: translateY(-100%);
    transition: all $duration $easing;
}
