.slide {
    position: absolute !important;
    background-color: white;

    &--display {
        z-index: 100;
    }

    &--preload {
        z-index: 0;
    }

    &--border {
        &-display {
            padding: 3% !important;
        }

        &-tiles {
            .content {
                padding: 3% !important;
            }
        }
    }
}
