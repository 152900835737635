@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.greeting {
    font-weight: bold;

    &--icon {
        margin-right: 10px;
    }

    hr {
        width: 95%;
        height: 3px;
        border: none;
    }

    &__header {
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 85%;

        &__text-row {
            font-size: 1.5rem;
            text-align: center;
            margin-bottom: 30px;

            span {
                display: inline-block;
                padding: 1rem;
            }
        }

        &__logo {
            align-self: center;
            margin-top: 60px;
        }
    }

    &__footer {
        height: 15%;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: stretch;

        &--item {
            width: 33%;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            padding: 10px;
            line-height: 1.5em;

            .greeting--icon {
                font-size: 1.5rem;
                padding: 10px;
                margin-right: 0;
            }
        }
    }
}
