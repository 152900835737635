@keyframes scale-animation-keyframes {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(1.07);
    }
}

.logo {
    flex-direction: column;
    position: absolute !important;
    z-index: 100000000000 !important;
    background: white;

    &__image {
        animation: scale-animation-keyframes alternate infinite ease-in-out 0.7s;
    }

    &__subtitle {
        font-weight: bold;
        font-size: larger;
        margin-top: 50px;
    }

    &--transition {
        &-exit {
            opacity: 1;
            width: 100%;
        }

        &-exit-active {
            opacity: 0;
            width: 0%;
            height: 0%;
            background: transparent;
            transition: all 1000ms ease-in-out;
        }

        &-exit-done {
            display: none !important;
        }
    }
}
