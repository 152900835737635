.digital-clock {
    &__text {
        padding: 0 30pt;
    }

    &__points {
        font-weight: bold;
    }

    &__hours,
    &__minutes,
    &__seconds {
        font-weight: bold;
    }
}
